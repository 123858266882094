@tailwind base;
@tailwind components;
@tailwind utilities;

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	-webkit-box-shadow: 0 0 0 30px white inset !important;
}

/* html,
body {
	height: 100vh;
	width: 100vw;
} */

#root {
	/* height: 100%; */
	width: 100%;
	background-color: rgb(229 231 235);
}

html,
body {
	height: 100%;
	overflow: auto;
}
